import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'


export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Utility-focused collection of PFP NFTs'),
  bodyText: t(
    'Cronos Cats are not only works of art, it is a utility-focused collection of PFP NFTs. CC NFT collection is used for staking, earning and entertainment.  In total, the collection contains 5,000 unique NFTs for the minting of which Cronos Cats Tokens are awarded.',
  ),
  reverse: true,
  primaryButton: {
    to: 'https://nft.cronoscats.club/mint',
    text: 'Mint',
    external: false,
  },
  secondaryButton: {
    to: 'https://cronoscats.club/nft-staking',
    text: 'Stake',
    external: true,
  },
  images: {
    path: '/images/cronoscats/nfts/',
    attributes: [
      { src: '1', alt: '1' },
      { src: '2', alt: '2' },
      { src: '3', alt: '3' },
    ],
  },
})
